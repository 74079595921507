import { LocalStorage } from 'quasar'
export default {
  name: 'Footer',
  data () {
    return {
    }
  },
  mounted () {
    if (LocalStorage.has('netcarros-cookies-aceitos')) {
      const aceitou = parseInt(LocalStorage.getItem('netcarros-cookies-aceitos'))
      if (this.$refs.cookies && parseInt(aceitou) === 0) {
        setTimeout(() => {
          this.$refs.cookies.classList.remove('aparecer')
          this.$refs.cookies.classList.add('aparecer')
        }, 2000)
      }
    } else {
      LocalStorage.set('netcarros-cookies-aceitos', 0)
      if (this.$refs.cookies) {
        setTimeout(() => {
          this.$refs.cookies.classList.remove('aparecer')
          this.$refs.cookies.classList.add('aparecer')
        }, 2000)
      }
    }
  },
  methods: {

    scrollQuemsomos () {
      if (this.$route.name === 'quem-somos') {
        setTimeout(() => {
          document.querySelector('#quem-somos').scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 200)
      } else {
        this.$router.push({ name: 'quem-somos' })
        setTimeout(() => {
          document.querySelector('#quem-somos').scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 200)
      }
    },
    aceitarCookies () {
      if (this.$refs.cookies) {
        LocalStorage.set('netcarros-cookies-aceitos', 1)
        setTimeout(() => {
          this.$refs.cookies.classList.remove('aparecer')
        }, 500)
      }
    }
  }
}
